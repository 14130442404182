// WHen debugging, track the number of times we push to the dataLayer,
// starting with what's already there (which should be 0)
let currentDataLayerIndex =
  typeof window === "undefined" ? 0 : window.dataLayer.length + window.consentQueue.length;

/**
 * Consent aware utility for pushing to the dataLayer.
 * Ensures important events will not be lost before consent
 * is set.
 *
 * @param {object} content - Object for DL
 * @param {boolean} requireConsent - Should this wait for OneTrust (almost always yes)
 */
export function pushDataLayer(content, requireConsent = true) {
  if (typeof window === "undefined") {
    console.error("pushDataLayer should not be run server-side");
    return;
  }

  const search = window.location.search;
  if (search.includes("gtm_debug") || search.match(/debug=(?:gtm|dl|datalayer)/i)) {
    console.log(
      `[datalayer] pushDataLayer`,
      { index: currentDataLayerIndex, requireConsent },
      content,
    );
    currentDataLayerIndex++;
  }

  if (requireConsent) {
    window.consentQueue.push(({ isFirstRun }) => {
      if (isFirstRun) window.dataLayer.push(content);
    });
  } else {
    window.dataLayer.push(content);
  }
}
